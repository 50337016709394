.bar {
    width: 100%;
    background-color: black;
    z-index: 99999;
    position: fixed;
    top: 0;
    transition: height .7s ease;
}

.icons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    color: aliceblue;
}

.bar-close {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 45px;
}

.bar-open {
    height: 30vh;
    display: flex;
    flex-direction: column;
}

.list {
    padding-block: 45px;
    margin: 0;
    list-style: none;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.list li a {
    text-decoration: none;
    color: aliceblue;
    font-size: 1.2em;
}