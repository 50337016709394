.section {
    height: 800px;
}

.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.title {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 20px;
    font-size: 3em;
    text-align: end;
}

.text {
    padding-left: 60px;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    text-align: end;
}

.text p {
    width: 50%;
    font-size: 20px;
}

.paragraphe {
    background: -webkit-linear-gradient(135deg, #001233 0%, #406ec3 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-right: 5px solid #001233;
    padding-right: 10px;
}

.paragraphe p {
    width: 100%;
    text-align: right;
    display: flex;
    justify-content: flex-end;
}

.timeline-area {
    width: 60%;
    display: flex;
    justify-content: center;
}

.timeline-area::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, Opera */
}

@media (max-width: 768px) {
    .section {
        height: 100vh;
    }

    .container {

        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

    }

    .timeline-area {

        width: 100%;
        display: flex;
        justify-content: center;
    }
    
    .text {
        padding-left: 0;
        width: 100%;
        height: 25%;
        display: flex;
        justify-content: center;
        align-self: center;
        text-align: center;
        padding-block: 25px;
        padding-inline: 10px;
    }
    .text p {
        width: 100%;
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .paragraphe {
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        padding-right: 10px;
        border: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .paragraphe p {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 20px;
        font-size: 2em;
    }

   
}