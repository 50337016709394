.section {
    padding-inline: 20px;
    padding-top: 6em;
    padding-bottom: 100px;
    height: 100vh;
    background-color: #000000;
    color: aliceblue;
    overflow: hidden;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 30px;
    font-size: 3em;
    color: aliceblue;
}

label {
    padding-bottom: 15px;
}

.name,
.email {
    outline: none;
    background: none;
    border: none;
    border-bottom: 2px solid aliceblue;
    height: 40px;
    color: aliceblue;
    letter-spacing: 2px;
    font-size: 1em;
    text-indent: 10px;
    font-weight: bold;
}

.message {
    outline: none;
    background: none;
    border: 2px solid aliceblue;
    height: 200px;
    padding: 17px;
    color: aliceblue;
    letter-spacing: 2px;
    font-size: 1em;
    text-indent: 10px;
    font-weight: bold;
}

.send-container {
    border: 2px solid aliceblue;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.send {
    cursor: pointer;
    color: aliceblue;
    font-weight: bold;
    width: 50%;
    height: 100%;
    font-size: 1.5em;
}

.form-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form {
    color: black;
    width: 80%;
    height: 50%;
    padding: 45px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.form div:not(:last-child) {
    display: flex;
    flex-direction: column;
    padding-bottom: 55px;
}

.form div label {
    color: white;
    font-size: 1.5em;
    font-weight: bold;
}

.form input[type="submit"] {
    background: none;
    border: none;
    width: 50%;
    padding: 10px 5px;
}

.container {
    height: 95%;
}



@media (max-width: 768px) {

    .form-container {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .title {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 30px;
        font-size: 1.5em;
        color: aliceblue;
    }

    .form {
        color: black;
        width: 90%;
        height: 50%;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .form div label {
        color: white;
        font-size: 1em;
        font-weight: bold;
    }

    .form input[type="submit"] {
        background: none;
        border: none;
        width: 50%;
        padding: 5px 5px;
    }

    .form div:not(:last-child) {
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
    }
}

@media (max-height: 670px) {

    .section {
        padding-inline: 20px;
        padding-top: 2em;
        padding-bottom: 100px;
        height: 100vh;
        background-color: #000000;
        color: aliceblue;
        overflow: hidden;
    }

    .title {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 30px;
        font-size: 1.5em;
        color: aliceblue;
    }

    .form div label {
        color: white;
        font-size: 1em;
        font-weight: bold;
    }

    .form input[type="submit"] {
        background: none;
        border: none;
        width: 50%;
        padding: 5px 5px;
    }

    .form div:not(:last-child) {
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
    }
}