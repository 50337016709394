.video-section {
    z-index: -5000;
    overflow: hidden;
    padding: 0;
    height: 100vh;
  }

#background-video {
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 0;
  }

.content {
    position: absolute;
    top: 65%;
    left: 22%;
    transform: translate(-50%, -50%);
    z-index: 1;
    color: aliceblue;
    padding: 20px;
}

.first-name {
    font-size: 60px;
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0.5);
}

.last-name {
    font-size: 60px;
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0.5);
}

.phrase {
    
    font-size: 20px;
    font-weight: bold;
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0.3);
}

.download {
    background-color: transparent;
    font-size: 20px;
    font-weight: bold;
    margin-top: 25px;
    padding: 10px 15px;
    color: aliceblue;
    outline: 0;
    cursor: pointer;
    border: 1px solid aliceblue;
    border-radius: 2px;
    transition: all ease-in .4;
}

.download:hover {
    background-color: aliceblue;
    color: black;
}

.fas.fa-flag {
    color: #0055A4; /* Couleur bleue du drapeau français */
    font-size: 1.2em;
}


@media (max-width: 768px) {
    .video-section {
        z-index: -5000;
        padding: 0;
        width: 100%;
        height: 100vh;
      }

      .content {
        position: absolute;
        top: 65%; /* Centré verticalement */
        left: 50%; /* Centré horizontalement */
        transform: translate(-50%, -50%); /* Correction du décalage */
        z-index: 1;
        color: aliceblue;
        padding: 20px;
        text-align: center; /* Pour centrer le texte */
    }
    
    .bg-pic {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        background-image: url('/public/eiffel-tower.jpg');
        background-size: cover;
        object-fit: cover;
        background-position: center;
        background-attachment: fixed;
        background-repeat: no-repeat;
    
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 0;
      }
  }