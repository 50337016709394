.section {
    padding-left: 0;
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    height: 800px;
    background: linear-gradient(#1c1c1c, #011e53);
}

.left-side {
    clip-path: polygon(0 0, 80% 0, 100% 100%, 100% 100%, 0 100%);
    position: relative;
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    background-image: url('/public/keyboard.jpg');
    /* Remplace par le chemin de ton image */
    background-size: cover;
    /* Pour couvrir toute la div */
    background-position: center;
    /* Centrer l'image */
    background-attachment: fixed;
    /* Rend l'image fixe lors du défilement */
    overflow: hidden;
}

.left-side::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Couleur noire semi-transparente */
    z-index: 1;
    /* Met le filtre au-dessus de l'image de fond */
}

.left-side>* {
    position: relative;
    z-index: 2;
    /* Assure que le contenu est au-dessus du filtre */
    color: white;
    /* Assure que le texte est visible sur le fond sombre */
}

.right-side {
    width: 40%;
    display: flex;
    align-items: center;
}

.title-box {
    position: relative;
    padding-left: 25px;

}

.title-box::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    background-color: aliceblue;
    width: 3px;
}

.title-box>* {
    position: relative;
    opacity: 0;
    transform: translateX(-20%);
    /* Positionne le contenu en dehors de la vue, à gauche */
    animation: slideIn 1s forwards;
    /* Applique l'animation de glissement */
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateX(-5%);
    }

    to {
        opacity: 1;
        transform: translateX(0);
        /* Fait glisser le contenu à sa position normale */
    }
}



.title {
    position: absolute;
    top: 5%;
    left: 5%;
    color: aliceblue;
    width: 100%;
    margin-bottom: 20px;
    font-size: 3em;
    font-weight: bold;
}


.pyramid>div {
    width: fit-content;
    display: flex;
    flex-direction: row;
}

.pyramid>div>span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    background-color: rgba(242, 243, 242, 0.242);
    margin: 1px;
    border-radius: 2px;
    border: 1px solid rgba(242, 243, 242, 0.439);
    --borderWidth: 3px;
    background: #1D1F20;
    position: relative;
    border-radius: var(--borderWidth);
    overflow: hidden;
    /* Assure que le ::after ne déborde pas */
    z-index: 1;
    /* Assure que le contenu est au-dessus de l'animation */
}

.pyramid>div>span::after {
    content: '';
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    border-radius: calc(2 * var(--borderWidth));
    z-index: -1;
    background: transparent;
    /* Le pseudo-élément commence transparent */
    transition: background 0.3s ease;
    /* Transition pour l'effet de survol */
}

.pyramid>div>span:hover::after {
    background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
}

@keyframes animatedgradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}



.global {
    font-size: 20px;
    font-style: italic;
    text-indent: 2em;
    color: aliceblue;
}

.skill-name {
    color: aliceblue;
    font-size: 2em;
}

@media (max-width: 768px) {

    .section {
        padding-left: 0;
        padding-block: 0;
        display: flex;
        flex-direction: column-reverse;
        position: relative;
        width: 100%;
        height: 100vh;
        background-image: url('/public/keyboard.jpg');
        /* Remplace par le chemin de ton image */
        background-size: cover;
        /* Pour couvrir toute la div */
        background-position: center;
        /* Centrer l'image */
        background-attachment: fixed;
        z-index: 2;
    }

    .section::after {
        content: "";
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1;
    }

    .title {
        position: relative;
        color: aliceblue;
        margin-bottom: 20px;
        font-size: 3em;
        font-weight: bold;
        z-index: 3;
        width: 90%;
        padding-bottom: 15px;
    }

    .left-side {
        position: relative;
        height: 100%;
        width: 100%;
        clip-path: none;
        display: flex;
        padding-left: 0;
        padding-top: 0;
        flex-direction: column;
        align-items: center;
        justify-content: start;
    }

    .right-side {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        padding-block: 15px;
        z-index: 3;
        background-color: none;
    }


    .left-side::before {
        display: none;
    }
    .pyramid {
        display: flex;
        justify-content: row;
        margin-bottom: 25px;
    }

    .pyramid>div {
        width: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: center;
    }


    .global {
        padding-block: 10px;
        font-size: 16px;
        font-style: italic;
        text-indent: 2em;
        color: aliceblue;
        z-index: 3;
    }

    .title-box {
        position: relative;
        padding-inline: 15px;
        padding-block: 25px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

    .title-box::after {
        content: "";
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
        background-color: transparent;
        z-index: 1;
    
    }
}

@media (max-height: 670px) {
    .section {
        padding-left: 0;
        padding-block: 50px;
        display: flex;
        flex-direction: column-reverse;
        position: relative;
        width: 100%;
        height: fit-content;
        background-image: url('/public/keyboard.jpg');
        /* Remplace par le chemin de ton image */
        background-size: cover;
        /* Pour couvrir toute la div */
        background-position: center;
        /* Centrer l'image */
        background-attachment: fixed;
        z-index: 2;
    }

    .title {
        position: relative;
        color: aliceblue;
        margin-bottom: 0px;
        font-size: 3em;
        font-weight: bold;
        z-index: 3;
        width: 90%;
        padding-bottom: 0px;
    }
}