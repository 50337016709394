.bar {
    position: fixed;
    top: 0;
    width: 100%;
    height: 65px;
    background-color: transparent;
    display: flex;
    align-items: center;
    z-index: 999999;
    background-color: transparent;
    transition: background-color 0.7s ease;

}

.scrolled {
    position: fixed;
    top: 0;
    width: 100%;
    height: 65px;
    background-color: transparent;
    display: flex;
    align-items: center;
    z-index: 999999;
    background-color: rgba(0, 0, 0, 1);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
}

.list {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    padding: 0 20px;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    color: aliceblue;
    font-weight: bold;
    font-size: 20px;
    width: 150px;
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0.5);
    text-decoration: none;
}

.links {
    display: flex;
    flex-direction: row;
}

.links li a {
    color: aliceblue;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold;
    text-decoration: none;
    margin-inline: 12px;
}

.links li a:hover {
    text-decoration: underline;
}

.dark {
    height: 100%;
    width: 150px;
    color: aqua;
    display: flex;
    align-items: center;
}


.buttonresume {
    color: aliceblue;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: bold;
}

.buttonresume span {
    margin-right: 12px;
    margin-left: 12px;
}
