.timeline {
    z-index: 25;
    position: relative;   
    height: 100vh;
    padding-inline: 80px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.timeline::after {
    content: '';
    position: absolute;
    width: 4px;
    background-color: black;
    height: 100vh;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 4;
}

.timeline::before {
    content: '';
    position: absolute;
    width: 4px;
    background-color: black;
    height: 100vh;
    top: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    z-index: 4;
}

.container {
    position: relative;
    padding: 10px 50px;
    width: 50%;
}

.text-box {
    position: relative;
    padding: 20px 30px;
    background: #001233;
    color: aliceblue;
    border-radius: 6px;
}

.text-box h2 {
    font-weight: 600;
}

.text-left {
    box-shadow: -8px 5px 5px rgb(167, 166, 170, 0.552);
}

.text-right {
    box-shadow: 8px 5px 5px rgba(167, 166, 170, 0.552);
}

.text-box small {
    display: inline-block;
    margin-bottom: 15px;

}

.left-container {
    left: 0;
}

.right-container {
    left: 50%;
}

.left-container-arrow {
    height: 0;
    width: 0;
    position: absolute;
    top: 28px;
    z-index: 1;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid #001233;
    right: -15px;
}

.right-container-arrow {
    height: 0;
    width: 0;
    position: absolute;
    top: 28px;
    z-index: 1;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid #001233;
    left: -15px;
}

.circle {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: black;
    border-radius: 50%;
    right: -10px;
    top: 42px;
    z-index: 10;
}

.right-container .circle {
    left: -10px;
}

@media (max-width: 768px) {
    .timeline {
        z-index: 25;
        height: fit-content;
        position: relative;   
        padding-inline: 5px;

    }
    
    .timeline::after {
        content: '';
        position: absolute;
        width: 4px;
        background-color: black;
        height: 100%;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 4;
    }
    
    .timeline::before {
        content: '';
        position: absolute;
        width: 4px;
        background-color: black;
        top: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        z-index: 4;
    }

    .container {
        z-index: 30;
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .text-box {
        position: relative;
        background: #001233;
        color: aliceblue;
        border-radius: 6px;
        width: 100%;
    }

    .text-box p {
        position: relative;
        background: #001233;
        color: aliceblue;
        border-radius: 6px;
        font-size: 1em;
    }

    .left-container {
        left: 0;
    }
    
    .right-container {
        left: 0;
    }
 
    .circle {
       display: none;
    }

    .left-container-arrow {
       display: none;
    }
    
    .right-container-arrow {
        display: none;
    }  
}