.section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(#001233, #1c1c1c);
    padding-block: 150px;
}

.title {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
    font-size: 3em;
    color: aliceblue;
}

.container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.container p {
    text-indent: 2em;
    width: 50%;
    padding-block: 20px;
    font-size: 22px;
    color: aliceblue;
}

@media (max-width: 768px) {
    .section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: linear-gradient(#001233, #1c1c1c);
        padding-block: 100px;
    }
    .container p {
        text-indent: 2em;
        width: 90%;
        padding-block: 20px;
        font-size: 22px;
        color: aliceblue;
        font-size: 1.2em;
    }
    
}